@use '../../assets/scss/variables.scss' as var;

.spinner-container {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    z-index: 200;

    img {
        width: 100px;
        height: 100px;
        animation: blank 1s linear infinite;
    }
}

@keyframes blank {
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
}