$blue : #2b307a;
$SoftBlue: #284a97;
$red : #e0301d; 
$white : white;
$black : black;

$TagSize : 1vw;
$BigTitleSize : 3vw;
$TitleSize : 2.4vw;
$SubTitleSize : 1.4vw;
$ParagraphSize : 1.1vw;
$CartFontSize : 0.9vw;
$ButtonSize : 1vw;
$TitleLineHeight : 1.2;
$ParagraphLineHeight : 1.5;