@use './assets/scss/variables.scss' as var;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
}

body {
    scroll-behavior: smooth;
}

.HeaderBtns {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 2px solid gray;
    padding-top: 15px;
    margin-top: 20px;

    button {
        margin-right: 30px;
        width: 150px;
        background-color: var.$blue;
        color: white;
        padding: 13px 0;
        border-radius: 15px;
    }
}

.Pen {
    background-color: rgb(224, 224, 224);
    color: rgb(95, 95, 95);
    padding: 10px;
    border-radius: 50%;
    z-index: 1;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    cursor: pointer;
    transition: all 0.2s ease;
    margin-right: 0.5vw;

    &:hover {
        color: var.$blue;
    }
}

.SocieteManager {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 50px 0px 300px;
    min-height: 100vh;
    width: 100%;




    .Tabs {
        background-color: var.$blue;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 0px;
        font-weight: 600;
        font-size: 15px;
        margin-bottom: 40px;
        border-radius: 4px;

        span {
            margin-right: 20px;
            cursor: pointer;
            color: var.$white;
        }

        .active {
            border-bottom: 1px solid var.$white;
        }
    }

    .ConfigCart {
        margin-bottom: 40px;
        width: 100%;
        padding: 20px;
        background-color: white;
        flex-direction: column;
        min-width: 80vw;
        border-radius: 15px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        display: none;
    }

    .showCart {
        display: flex;
    }
}

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgb(0, 0, 0, 0.5);
    z-index: 10;
}

.ChangeBox {
    display: flex;
    align-items: center;
}

.meta-param {
    width: 100%;

    h2 {
        font-size: 2vw;
        font-weight: bold;
        color: var.$blue;
        margin-bottom: 2vw;
    }

    .Formx {
        width: 60vw;
        margin-left: 10vw;
        display: flex;
        flex-direction: column;

        .FormElem {
            display: flex;
            flex-direction: column;
            label {
                font-size: 1.2vw;
                font-weight: 600;
                color: var.$red;
                margin-bottom: 2vw;
                margin-top: 2vw;
                width: 20%;
                text-align: left;
            }

            input {
                border: 3px solid var.$blue;
                height: 4vw;
                width: 80%;
                border-radius: 30px;
                padding-left: 10px;
            }

            textarea {
                border: 3px solid var.$blue;
                height: 8vw;
                width: 80%;
                border-radius: 30px;
                padding-top: 20px;
                padding-left: 10px;
            }
        }

    }

}

.CartModel {
    position: fixed;
    top: 0%;
    left: 40%;
    height: calc(100vh - 10px);
    width: 400px;
    background-color: white;
    z-index: 20;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    animation: fadeDown 0.3s ease-in-out;
    overflow-y: auto;


    .imageChangeBTN {
        background-color: var.$blue;
        color: white;
        font-weight: 600;
        padding: 10px 20px;
        border-radius: 10px;

        .icon {
            margin-right: 5px;
        }
    }

    img {
        object-fit: cover;
        width: 100px;
        max-height: 100px;
        border: 2px solid var.$blue;
        padding: 2px;
    }
    input {
        border: 1px solid black;
        height: 50px;
        width: 80%;
        padding-left: 10px;
        border-radius: 6px;
    }

    label {
        font-weight: 600;
        margin-bottom: 10px;
    }

    .Btns {
        margin: 20px 0;
        width: 80%;
        display: flex;
        align-items: center;
        justify-content: space-around;

        button {
            background-color: var.$red;
            padding: 10px 20px ;
            border-radius: 10px;
            color: white;
            font-weight: 600;
        }
    }
}

